$mobile-width: 480px;
$desktop-width: 1440px;
$laptop-width: 992px;
$form-width: 767px;
$dark-blue-color: #233464;
$blue-unique: #173f5f;
$blue-unique2: #20639b;
$blue: #0966f1;
$light-blue: #d5f3fe;
$blue2: #3c99dc;
$blue3: #66d3fa;
$light-gray: #cfd7e4;
$yellow: #f6d55c;
$red: #ed553b;
$red-p: #ff0d00;
$green: #3caea3;
$carrot: #ed9121;
$orange: #fbdeb3;
$dark-orange: #efb261;

// Mixin
@mixin mobile() {
  @media screen and (max-width: $mobile-width) {
    @content;
  }
}
@mixin desktop() {
  @media screen and (max-width: $desktop-width) {
    @content;
  }
}
// End mixin

// @font-face {
//   font-family: "Lato";
//   src: url("./assets/fonts/Lato-Regular.ttf");
//   font-display: swap;
// }
.base-font-class {
  margin-bottom: 2em;
  letter-spacing: 0.12em;
  word-spacing: 0.16em;
  line-height: 1.5em;
}
.normal-font {
  @extend .base-font-class;
  font-family: "Lato", sans-serif;
  font-display: swap;
}
* {
  box-sizing: border-box;
}
html,
body {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  min-height: 100%;
  display: grid;
  margin: 0;

  //   font-weight: 400;
  //   line-height: 1.5;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  display: grid;
  grid-template-rows: auto 1fr min-content;
  grid-template-columns: 100%;
}

.paragraph,
.span {
  max-width: 80ch;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif;
  color: $dark-blue-color;
  // line-height: 1.5em;
}
.page-header {
  font-size: 2.5rem;

  @include mobile() {
    font-size: 2rem;
  }
}
.large-header {
  font-size: 2rem;

  @include mobile() {
    font-size: 1.75rem;
  }
}
.medium-header {
  font-size: 1.5rem;
  margin: 2rem 0;
}
.normal-header {
  font-size: 1.25rem;
}
.small-header {
  font-size: 1.15rem;
}
.tiny-header {
  font-size: 1.1rem;
}
.lead {
  font-size: 1.125rem;
}

header {
  div {
    max-width: 1280px;
    width: 90%;
    padding: 1.5rem 0;
    margin: auto;

    .app-logo {
      max-width: 200px;
      min-height: 40px;
      @include mobile() {
        max-width: 150px;
        max-height: 50px;
      }
    }
  }
}

.footer-wrapper {
  .information-link {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.75rem 0;
    color: $blue-unique;
    flex-wrap: wrap;
    a,
    span {
      font-size: 1.1rem;
      font-weight: 550;
      @include mobile() {
        display: none;
      }
    }

    a {
      display: inline-flex;
      color: $blue-unique;
      margin: 0.5rem;
      padding: 0.25rem;
    }
  }
}

footer {
  padding: 2.5rem 0;
  background-color: $blue-unique;
  color: #fff;
  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    color: #fff;
  }
  a {
    color: lighten($blue, 37%);
  }
}
.contact {
  @extend .form-container;
  text-align: center;
  p {
    // text-align: justify;
    // text-align-last: center;
    margin: auto;
    max-width: 500px;
  }
  a {
    text-decoration: underline;
  }
}

.form-container {
  width: 90%;
  max-width: 767px;
  margin: auto;
}

.main-navigation {
  background-color: $blue-unique;
  padding: 1.75rem 0;
  margin-bottom: 3rem;

  ul {
    @extend .form-container;
    padding: 0;
    display: flex;
    justify-content: space-evenly;
    justify-self: center;
    list-style: none;
    counter-reset: item;
    flex-wrap: wrap;

    li {
      // text-transform: capitalize;
      position: relative;
      font-family: "Lato", sans-serif;
      @include mobile() {
        flex-basis: 50%;
      }

      a {
        color: #fff;
        display: block;
        font-size: 1.05rem;
        text-align: center;
        margin: 0.5rem;
        padding: 0.25rem;

        &::before {
          counter-increment: item;
          content: counter(item);
          display: flex;
          margin: auto;
          margin-bottom: 0.5rem;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          line-height: 2rem;
          width: 2rem;
          height: 2rem;
          border: 2px solid #fff;
          color: #fff;
          text-decoration: none;
        }
        &.ready {
          &::before {
            border-style: dotted;
          }
        }
        &.active {
          cursor: pointer;
          &::before {
            background-color: #fff;
            color: $dark-blue-color;
            border-style: solid;
            border-color: #fff;
          }
        }
        &.current-active {
          cursor: context-menu;
          &::before {
            font-weight: bold;
            color: $red-p;
            border-color: $red;
          }
        }
      }
    }
  }
}

//Form

.radio-label {
  display: flex;
  width: fit-content;
  align-items: center;
  position: relative;
  margin-bottom: 0.3rem;
  // cursor: pointer;
  font-size: 1.05rem;
  user-select: none;
  border: 1px solid transparent;

  input {
    position: absolute;
    opacity: 0;
    // cursor: pointer;

    &:focus {
      + .radio-symbol {
        background-color: lighten(#cfd7e4, 7%);
        // border-color: #233464;
      }
    }
  }
  &:hover {
    background-color: lighten(#cfd7e4, 7%);
    // border-color: #233464;
  }

  .radio-symbol {
    position: relative;
    display: flex;
    padding: 0.5rem;
    padding-left: 2.5rem;
    align-items: flex-start;

    &:before {
      position: absolute;
      content: "";
      height: 1rem;
      width: 1rem;
      background-color: lighten(#cfd7e4, 12%);
      border: 1px solid darken($dark-blue-color, 15%);
      border-radius: 50%;
      display: flex;
      align-items: center;
      top: 10px;
      justify-content: center;
      left: 0.5rem;
    }
  }

  input:checked {
    ~ .radio-symbol {
      &:before {
        background-color: darken($blue, 10%);
        border-color: #fff;
        box-shadow: 0 0 1px 1px $dark-blue-color;
        border-width: 2px;
      }
    }
  }
}

.checkbox-label {
  display: flex;
  width: fit-content;
  align-items: center;
  position: relative;
  margin: 0.75rem 0;
  // cursor: pointer;
  font-size: 1.05rem;
  user-select: none;
  border: 1px solid transparent;

  input {
    position: absolute;
    opacity: 0;
    // cursor: pointer;

    &:focus {
      + .checkbox-symbol {
        background-color: lighten(#cfd7e4, 7%);
        // border-color: #233464;
      }
    }
  }
  &:hover {
    background-color: lighten(#cfd7e4, 7%);
    // border-color: #233464;
  }

  .checkbox-symbol {
    position: relative;
    display: flex;
    padding: 0.5rem;
    padding-left: 2.75rem;
    align-items: flex-start;

    &:before {
      position: absolute;
      content: "";
      height: 1.1rem;
      width: 1.1rem;
      background-color: lighten(#cfd7e4, 12%);
      border: 2px solid lighten(#233464, 8%);
      border-radius: 2px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      left: 0.5rem;
    }
  }

  input:checked {
    ~ .checkbox-symbol {
      &:before {
        background-color: lighten(#233464, 10%);
      }
      &:after {
        content: "";
        position: absolute;
        display: flex;
        left: 15px;
        top: 9px;
        width: 5px;
        border-radius: 1px;
        height: 12px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
      }
    }
  }
}

input[type="date"] {
  &::-webkit-inner-spin-button,
  &::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
    opacity: 0;
  }

  appearance: none;
  background-image: url("./assets/images/calendar.png");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 1rem);
  background-position-y: 50%;
  min-height: 51.75px;
}

.form-navigation {
  @extend .form-container;
  margin: 3rem 0;
  display: flex;
  flex-flow: row wrap;

  a {
    text-decoration: none;
  }
}
.navigation-button {
  border: 2px solid $dark-blue-color;
  background: none;
  border-radius: 3px;
  padding: 0.8rem 1.5rem;
  background-color: $dark-blue-color;
  color: #fff;
  font-size: 1.05rem;
  margin: 0 1rem 1rem 0;
  cursor: pointer;
  &:hover,
  &:focus,
  &:active {
    background-color: $orange;
    color: $dark-blue-color;
  }
}

.input-label {
  margin: 2.1rem 0;
  display: block;
  span {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 1.05rem;
  }

  input[type="text"],
  input[type="email"],
  input[type="date"],
  textarea,
  select {
    width: 100%;
    box-sizing: border-box;
    font-size: 1.05rem;
    background-color: lighten(#cfd7e4, 14%);
    padding: 0.8rem;
    border: 1px solid darken(#cfd7e4, 25%);
    border-radius: 3px;
    min-height: 51.75px;
    &:focus,
    &:hover {
      background-color: lighten(#cfd7e4, 12%);
    }
  }
  textarea {
    min-height: 6rem;
  }
}

select {
  appearance: none;
  background-image: url("./assets/images/arrow-down.png");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 1rem);
  background-position-y: 50%;
  min-height: 51.75px;
}
//File
.file-label {
  // display: flex;

  &__symbol {
    min-width: 170px;
    min-height: 60px;
    font-size: 1.15rem;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #233464;
    padding: 1rem 3.5rem;
    border-radius: 5px;
    background-color: lighten(#cfd7e4, 10%);
    background-image: url("./assets/images/upload.png");
    background-repeat: no-repeat;
    background-position: calc(100% - 0.75rem);
    background-size: 1.25rem;
  }

  input[type="file"] {
    opacity: 0;
    position: absolute;
    width: 0;
    &:focus,
    &:active,
    &:hover {
      + span {
        background-color: #cfd7e4;
      }
    }
  }
}
.file-list {
  list-style: none;
  padding: 0;
  margin: 1.5rem auto;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.3rem 0.75rem;
    background-color: lighten(#cfd7e4, 5%);
    margin-bottom: 3px;
    border-radius: 5px;
    font-size: 1.11rem;
    color: darken(#233464, 10%);
    width: 100%;
  }
  button {
    cursor: pointer;
    border: none;
    background: none;
    color: #8b0000;

    span {
      display: block;
      font-size: 2rem;
    }
  }
}

.error-message {
  width: 100%;
  color: lighten(#8b0000, 3%);
  padding: 0.8rem;
  margin-top: 1rem;
  font-size: 1.05rem;
  background-color: lighten(#8b0000, 69%);
}

.no-pointer-events {
  cursor: not-allowed;
}
.receiptInfo {
  margin-bottom: 3rem;
}
.buttonDisabled {
  background-color: #cfd7e4;
  color: darken(#353535, 10%);

  &:hover {
    background-color: #cfd7e4;
    color: darken(#353535, 10%);
    cursor: context-menu;
  }
}
.form-error {
  width: 100%;
  padding: 1.5rem 1rem;
  background-color: #f0f092;
  color: #000000;
  margin-top: 2rem;
}

@media (max-width: 700px) {
  .ChatFooter {
    display: block;
    padding: 15px;
    border: 1px solid grey;
    width: 30%;
    margin: 0;
    text-align: center;
    position: relative;
    border-bottom: 0;
    margin-top: 2%;
    padding-bottom: 5%;
    border-right: 0;
    right: -70%;
    border-top-left-radius: 25px;
  }

  // #root {
  //   display: none !important;
  // }

  .SuccessDiv {
    position: fixed;
    top: 30%;
    left: 5%;
    right: 5%;
    bottom: auto;
    margin: auto;
    z-index: 9999;
    width: 90%;
    text-align: center;
    background-color: white;
    border: solid black 1px;
    padding: 15px;
  }
}

.semiBold {
  font-weight: 500;
}

.loading {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:after {
    content: "";
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-color: lighten(#cfd7e4, 7%);
    border-right-color: lighten(#233464, 25%);
    animation: spin 1.5s linear infinite;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1turn);
  }
}

.access-denied {
  padding: 1rem;
}

.response-wrapper {
  margin-top: 5rem;
  img {
    display: block;
    max-width: 250px;
    margin: 0 0 2rem;
  }
}

.required {
  &:after {
    content: "*";
    margin-left: 0.75rem;
    color: #e91527f1;
    font-weight: bold;
  }
}

.alert-message {
  @extend .error-message;
  font-weight: 500;
  font-size: 1.5rem;
  margin: 3rem auto;
  max-width: 960px;
}

.question-header {
  display: block;
  margin: 1.25rem 0;
  // font-size: 1.075rem;
  font-weight: 550;
  color: $dark-blue-color;

  &__initial {
    display: initial;
  }
  &__extra-space {
    margin: 1.75rem 0;
  }
  &__black {
    color: #000;
  }
}

.display-block {
  display: block;
}

.mobile-display-block {
  @include mobile() {
    display: block;
  }
}

/// kjopforsikring - extra

.logo-container {
  display: flex;
  justify-content: space-between;
}

.language-container {
  background-image: url("./assets/images/language.svg");
  background-size: 2rem;
  background-repeat: no-repeat;
  background-position: 5px 50%;
  margin-right: 0;
  padding-left: 2.75rem;
  flex-basis: fit-content;
  display: inline-flex;
  align-items: center;

  button {
    background: none;
  }
  button,
  span {
    font-size: 1.1rem;
    color: $dark-blue-color;
  }
  &__disabled {
    cursor: pointer;
    border: none;
  }
  &__active {
    cursor: context-menu;
    font-weight: 600;
    border: none;
  }
}
.not-dnb {
  padding: 0.75rem 1rem;
  color: lighten(#8b0000, 3%);
  background-color: lighten(#8b0000, 69%);
  margin-top: 1.25rem;

  &__contact,
  span {
    color: #000;
  }
  a {
    color: $dark-blue-color;
    display: inline-flex;
    margin-left: 0.25rem;
  }
}

// Deviation number input
input[type="number"] {
  appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

.number-input {
  display: flex;
  align-items: flex-start;
  > span,
  button {
    width: 2.5rem;
    height: 3rem;
    margin: 0;
    display: inline-flex;
    font-size: 1.1rem;
    font-weight: 600;
    border: none;
  }
  > span {
    background-color: $light-blue;
    justify-content: center;
    align-items: center;
  }

  button {
    background-color: $blue-unique;
    color: #fff;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
    background-position: 50%;
    background-repeat: no-repeat;
    z-index: 9;
    &:active,
    &:hover,
    &:focus {
      background-color: #000;
    }
  }
  .minus {
    background-image: url("./assets/images/minus1.svg");
  }
  .plus {
    background-image: url("./assets/images/plus3.svg");
    background-size: 2rem;
  }
}
.panel-container {
  display: flex;

  &__button {
    padding: 1rem 1.5rem;
    background-color: lighten($light-gray, 7%);
    border: none;
    border: 2px solid $blue-unique;
    font-size: 1rem;
    cursor: pointer;
    margin: 0.25rem 0.2rem 0.25rem 0;

    &__selected {
      background-color: $dark-orange;
      color: #000;
    }
    &:focus,
    &:hover,
    &:active {
      background-color: $dark-orange;
      color: #000;
    }
  }
}
.link-back {
  display: block;
  margin-top: 3rem;
  margin-bottom: 2rem;
  color: $blue-unique;
  font-size: 1.25rem;
  // font-weight: 550;
  position: relative;
  padding-left: 2rem;
  &::before {
    // display: inline-flex;
    // align-self: center;
    // content: "\1F860";
    // font-size: 1.75rem;
    // text-decoration: none;
    // left: 0;
    // position: absolute;
    content: "";
    background-image: url("./assets/images/arrow-left-blue.svg");
    background-size: 1.75rem;
    width: 1.25rem;
    display: inline-flex;
    background-repeat: no-repeat;
    background-position: 0 50%;
    position: absolute;
    left: 2px;
    height: 1.25rem;
    top: 10px;
  }
}
.information-container {
  .arrow {
    &:after {
      // content: "🡓"; //\2193 🠛🠣🡣🡓
      // display: flex;
      // font-size: 3rem;
      // justify-content: center;
      // margin: 2rem auto;
      // color: $blue-unique2;

      display: flex;
      content: "";
      background-image: url("./assets/images/arrow-down.svg");
      background-size: 3rem;
      margin: 2.5rem auto;
      background-repeat: no-repeat;
      background-position: 50%;
      height: 3rem;
    }
  }
}
.spinner {
  left: 0;
  top: 0;
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
  background: rgba(0, 0, 0, 0.2);
  z-index: 999;

  &__content {
    display: flex;
    flex-direction: column;
    background: #fff;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: 1.5rem 1rem;
    min-width: 280px;
  }
  &__animation {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid transparent;
    border-color: lighten(#cfd7e4, 7%);
    border-right-color: $dark-blue-color;
    animation: spin 1.5s linear infinite;
  }
}
.no-display {
  display: none;
}
.padding-small {
  padding: 0.75rem;
}

// Html wrapper

#htmlWrapper {
  div {
    margin-bottom: 3rem;
  }
  h4 {
    @extend .normal-header;
  }
  h5 {
    @extend .small-header;
    color: black;
  }

  strong {
    @extend .question-header;
    display: initial;
    color: black;
  }
}

.copyright {
  font-size: 0.85rem;
}
